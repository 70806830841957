.imageContainer {
  position: relative;
  height: 120px;
  min-width: 120px;
  width: 120px;
}

.rightAlign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  font-family: 'Roboto', sans-serif, Arial, Helvetica;
  font-size: 14px;
}

.image {
  width: 100%;
  height: 120px;
  object-fit: contain;
}

.textRed {
  color: red;
}

.fieldHint {
  display: block;
  font-size: 12px;
  color: #666;
  margin-top: 4px;
  margin-bottom: 0;
  font-style: italic;
  line-height: 1.2;
  clear: both;
}

.productSKUHeading {
  margin-bottom: 15px !important;
}

.editProductSKUs input {
  padding: 0px 20px;
  height: 50px;
}

.editProductSKUs {
  margin-top: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-bottom: 10px;
}

.editProductSKUs:first-child {
  margin-top: 0px;
}

.stockBadge {
  position: absolute;
  top: 38px;
  /* Adjust as needed */
  left: 8px;
  /* Adjust as needed */
  padding: 2px 8px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 12px;
  border-radius: 4px;
}

.inStock {
  background-color: green;
}

.outOfStock {
  background-color: red;
}

.syncBadge {
  position: absolute;
  top: 8px;
  /* Adjust as needed */
  left: 8px;
  /* Adjust as needed */
  padding: 2px 8px;
  background-color: #1976d2;
  /* Adjust as needed */
  color: white;
  font-size: 12px;
  border-radius: 4px;
}

.productSyncContainer {
  padding: 50px 0px;
  max-width: 95% !important;
}

.mainHeadings {
  text-align: center;
  font-size: 40px !important;
  font-weight: 700 !important;
  margin-bottom: 30px !important;
  display: block;
}

.searchBoxSytles {
  margin: 0 auto;
  gap: 0px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap !important;
}

.searchField {
  width: 100%;
}

.searchField input {
  padding: 0px 20px !important;
  font-size: 14px;
  height: 50px;
}


.searchBtn button {
  height: 50px;
  padding: 0px 30px !important;
}

.productTable {
  margin-top: 40px;
  min-height: 250px;
}

.productTable th {
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
}

.productTable td {
  text-align: center;
}

.modalMainHeading {
  text-align: center;
  font-size: 20px !important;
  font-weight: 700 !important;
  text-transform: capitalize;
  max-width: 80%;
  margin: 0 auto !important;
}

.editProductSelectbox {
  margin-top: 16px !important;
}

.editProductForm label {
  font-weight: 600;
}

.iconBtnStyles {
  position: absolute !important;
  top: 6px;
  right: 6px;
  background-color: white !important;
  padding: 5px !important;
}

.iconBtnStyles svg {
  width: 16px !important;
  height: 16px !important;
  fill: red;
}

.modalBtnsRow {
  margin-top: 10px;
}

.selectModeControl {
  margin-left: 15px;
}

.selectLimitControl {
  margin-left: 15px;
}

.editProductForm label {
  background-color: #ffffff;
  margin-right: 0px;
}

.editProductForm label,
.editProductForm input,
.editProductForm p {
  font-size: 14px;
  color: #000000;
}

.colLeft {
  padding-right: 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.colRight {
  padding-left: 15px;
}

.fieldRow {
  margin-bottom: 15px;
}

.editProductContainer {
  display: flex;
  flex-flow: column;
  gap: 20px 0px;
}

.lightText {
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 2px;
  display: block;
}

.fieldsBox {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-bottom: 10px;
}

.categoryFieldsBox label {
  padding: 0px 5px;
}

.fieldStyles input {
  height: 50px;
  padding: 0px 15px;
  border-radius: 0px;
}

.filterSelectbox>div {
  padding: 0px 15px;
  line-height: 17px;
}

.filterSelectbox div {
  height: 50px !important;
  display: flex;
  align-items: center;
}

.filterSelectbox {
  display: flex;
  align-items: center;
}

.categoryFieldsBox>div>div>div>div>div {
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.categoryFieldsBox>div>div>div>div {
  display: flex;
  align-items: center;
}

.categoryFieldsBox .colLeft {
  border: none;
  margin-bottom: 20px;
  padding: 0px;
  position: relative;
}

.categoryFieldsBox .colRight {
  padding: 0px;
}

.categoryFieldsBox label {
  padding-left: 0px;
  margin-bottom: 10px;
}

/*
** Products Page
*/
.productTableCheckbox {
  width: 50px;
  min-width: 50px;
  padding: 0px;
}

.productTableImage {
  width: 120px;
  min-width: 120px;
  padding: 20px 10px;
}

.productTableImage .imageContainer {
  border: 1px solid #cccccc;
  background-color: #eeeeee;
}

.productTableName {
  width: 115px;
  min-width: 115px;
  padding: 20px 10px;
}

.productTableProductType {
  width: 110px;
  min-width: 110px;
  padding: 20px 10px;
}

.productTableStatus {
  width: 80px;
  min-width: 80px;
  padding: 20px 10px;
}

.productTableInStock {
  width: 120px;
  min-width: 120px;
  padding: 20px 10px;
}

.productTableSKU,
.productTablePrice,
.productTableCategory {
  width: 100px;
  padding: 20px 10px;
  min-width: 100px;
}

.productTableAction {
  width: 90px;
  padding: 20px 10px;
  min-width: 90px;
}

.productPaginations {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.productsPerPage .perPageSelectbox {
  min-width: 130px;
}

.productsPerPage {
  margin-right: 0px;
  margin-left: auto !important;
}

.productPag {
  margin-left: auto !important;
}

.searchBoxes {
  width: max-content;
  display: flex;
  align-items: center;
  min-width: max-content;
  gap: 0px 15px;
}

.closeModalBtn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.editProductGallery label {
  margin-bottom: 10px;
}

.editProductPrices {
  margin-top: 10px !important;
  display: block !important;
}

.editProductQuantity {
  margin-top: 48px !important;
}

.confirmationContent {
  padding: 0px !important;
  height: 88%;
}

.textCenter {
  text-align: center;
}

.confirmationBtns {
  justify-content: center !important;
}

.closeModalBtn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.cancelBtns {
  background-color: #bdbdbd !important;
}

/*
** Responsive
*/

@media (max-width: 767px) {
  .modalMainHeading {
    font-size: 18px !important;
  }

  .categoryFieldsBox>div>div>div>div {
    padding-right: 60px !important;
  }

  .categoryFieldsBox {
    flex-wrap: wrap;
  }

  .colLeft,
  .colRight {
    width: 100%;
    min-width: 100%;
    padding: 0;
    border: none;
  }

  .colLeft {
    margin-bottom: 20px !important;
  }

  .switchRows {
    flex-wrap: wrap;
  }

  .switchRows label {
    flex-wrap: wrap;
  }

  .switchRows label b {
    display: block;
    width: 100%;
  }

  .fullWidthOnMobile {
    width: 100% !important;
    min-width: 100%;
    justify-content: flex-start !important;
  }

  .fullWidthOnMobile .lightText {
    display: block !important;
    white-space: normal;
  }

  .productSyncSwitch {
    flex-wrap: wrap;
    display: flex;
  }

  .searchBoxSytles {
    flex-wrap: wrap !important;
    gap: 15px;
  }
}

@media (max-width: 600px) {
  .mainHeadings {
    font-size: 30px !important;
    margin-bottom: 20px !important;
  }

  .searchField,
  .searchBtn {
    width: 100%;
  }

  .searchBoxSytles {
    padding: 0px;
    gap: 15px 0px;
    display: flex;
    align-items: center;
  }

  .searchBoxes {
    min-width: 100%;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
  }

  .searchBtn button {
    width: 100%;
  }

  .iconBtnStyles {
    padding: 2px !important;
  }

  .modalBtnsRow {
    justify-content: center !important;
    flex-wrap: wrap;
    gap: 15px 0px;
  }

  .modalBtnsRow button {
    width: 100%;
  }

  .modalBtnsRow button:last-child {
    margin-left: 0px;
  }

  .selectModeControl,
  .selectLimitControl {
    width: 100%;
    margin-left: 0;
  }

  .selectModeControl,
  .selectModeBox {
    width: 100%;
    min-width: 100%;
  }

  .productSyncContainer {
    padding: 50px 15px;
    width: 100%;
    max-width: 100%;
  }

  .productPaginations {
    flex-wrap: wrap;
  }

  .productPag,
  .productsPerPage {
    width: 100%;
  }
}